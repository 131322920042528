'use client'
import {
  SummaryBoxesWidgetHandler,
  SummaryBoxesWidgetHandlerProps
} from 'storefront-modules/widgets'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'

type CustomSummaryBoxesWidgetProps = SummaryBoxesWidgetHandlerProps

export const CustomSummaryBoxesWidget = ({ sx, ...props }: CustomSummaryBoxesWidgetProps) => {
  const { mobileItemHeight } = useUIGlobalsContext()

  return (
    <SummaryBoxesWidgetHandler
      sx={{
        '.summary-boxes__item-image': {
          objectFit: 'cover'
        },
        '.summary-boxes__item-image-wrapper': {
          h: { base: mobileItemHeight, md: '400px' }
        },
        ...sx
      }}
      {...props}
    />
  )
}
