'use client'
import HeroWidget, {
  HeroWidgetProps,
  HeroWidgetWrapperProps
} from 'storefront-modules/widgets/components/HeroWidget'
import { FC, useMemo } from 'react'
import { GrandAspectRatio } from 'ui'
import { Flex, FlexProps } from '@chakra-ui/react'

type CustomHeroWidgetProps = HeroWidgetProps

const Wrapper: FC<HeroWidgetWrapperProps> = ({ type, children, ...props }) => {
  if (type === 'RATIO') {
    return (
      <GrandAspectRatio<FlexProps, 'w'>
        breakpoints={{
          base: '4:3',
          sm: '16:9'
        }}
        {...props}>
        {children}
      </GrandAspectRatio>
    )
  }
  return (
    <Flex pb={{ base: '60%', md: '30%' }} position="relative" w="full" {...props}>
      {children}
    </Flex>
  )
}

export const CustomHeroWidget = ({ ...props }: CustomHeroWidgetProps) => {
  const customProps = useMemo<CustomHeroWidgetProps['customProps']>(
    () => ({
      customComponents: {
        Wrapper
      },
      dividerProps: {
        width: '10%'
      }
    }),
    []
  )

  return <HeroWidget customProps={customProps} {...props} />
}
