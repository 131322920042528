import WideWidgetHandler, {
  WideWidgetHandlerProps
} from 'storefront-modules/widgets/components/WideWidget/WideWidgetHandler'

type CustomWideWidgetProps = WideWidgetHandlerProps

export const CustomWideWidget = (props: CustomWideWidgetProps) => {
  return (
    <WideWidgetHandler
      customProps={{
        sectionProps: {
          py: 0,
          px: 0
        },
        itemsWrapperProps: {
          p: 2
        }
      }}
      {...props}
    />
  )
}
