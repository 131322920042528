'use client'
import CampaignHeroWidget, {
  CampaignHeroWidgetProps,
  CampaignHeroWidgetWrapperProps
} from 'storefront-modules/widgets/components/CampaignHeroWidget'
import { FC, useMemo } from 'react'
import { GrandAspectRatio, GrandSection } from 'ui'
import { Flex } from '@chakra-ui/react'

type CustomCampaignHeroWidgetProps = CampaignHeroWidgetProps

const Wrapper: FC<CampaignHeroWidgetWrapperProps> = ({ type, children, href, ...props }) => {
  if (type === 'RATIO') {
    return (
      <GrandSection
        contentContainerProps={{
          p: 0
        }}
        noWidthLimit
        px={0}
        py={0}
        sectionId="campaign-hero">
        <GrandAspectRatio
          breakpoints={{
            base: '4:3',
            sm: '33:10'
          }}
          {...{ href }}
          {...props}>
          {children}
        </GrandAspectRatio>
      </GrandSection>
    )
  }
  return (
    <Flex pb={{ base: '60%', md: '30%' }} position="relative" w="full" {...{ href }} {...props}>
      {children}
    </Flex>
  )
}

export const CustomCampaignHeroWidget = (props: CustomCampaignHeroWidgetProps) => {
  const customProps = useMemo<CustomCampaignHeroWidgetProps['customProps']>(
    () => ({
      customComponents: {
        Wrapper
      }
    }),
    []
  )

  return <CampaignHeroWidget customProps={customProps} {...props} />
}
